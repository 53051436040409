<template>
  <div>
    <Overlay :overlay="overlay" />
    <v-card elevation="0">
      <v-toolbar dense class="elevation-0">
        <h3>{{ StoreObj.organization_name }}'s User Attendance</h3>
        <v-spacer />
        <v-menu right bottom :close-on-content-click="CloseValue" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              class="primary white--text ml-2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          <v-card width="220px">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="from_date"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="from_date"
                  label="Starting Date"
                  outlined
                  dense
                  class="mt-6 mx-6 FontSize"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="from_date" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(from_date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              :return-value.sync="to_date"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="to_date"
                  label="Ending Date"
                  outlined
                  dense
                  width="290px"
                  class="mx-6 FontSize"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :min="from_date + 1"
                v-model="to_date"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu1 = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu1.save(to_date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-select
              dense
              outlined
              item-text="text"
              item-value="value"
              class="FontSize mx-6"
              label="Attendance Status"
              v-model="attendance_status"
              :items="attendance_status_items"
            >
            </v-select>
            <div class="text-center">
              <v-btn
                color="primary"
                small
                class="mb-8 mt-n1"
                :close-on-content-click="false"
                @click="SortByDateMethod"
              >
                <v-icon class="white--text" small>mdi-filter</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                small
                class="ml-2 mb-8 mt-n1"
                :close-on-content-click="false"
                @click="ClearFilterMethod"
              >
                <v-icon class="white-text" small>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-menu>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          dense
          fixed-header
          :loading="loading"
          :headers="headers"
          :height="TableHeight"
          :items-per-page="100"
          style="cursor: pointer"
          :items="GetOrgAttandanceList"
          :no-data-text="noDataText"
          :footer-props="{
            'items-per-page-options': [100, 200, 500],
          }"
        >
          <template v-slot:[`item.sl_no`]="{ item }">
            <div class="FontSize">
              {{ GetOrgAttandanceList.indexOf(item) + 1 }}
            </div>
          </template>
          <template v-slot:[`item.user_name`]="{ item }">
            <div class="FontSize" v-if="item.user_name != undefined">
              {{ item.user_name }}
            </div>
            <div v-else>-</div>
            <div class="FontSize" v-if="item.user_full_number != undefined">
              {{ item.user_full_number }}
            </div>
            <div v-else>-</div>
          </template>
          <template v-slot:[`item.attendance`]="{ item }">
            <span
              class="red--text FontSize"
              :class="
                item.attendance_status == 'IN' ||
                item.attendance_status == 'OUT'
                  ? 'green--text'
                  : 'red--text'
              "
              >{{
                item.attendance_status == "IN" ||
                item.attendance_status == "OUT"
                  ? "PRESENT"
                  : "ABSENT"
              }}</span
            >
            <div
              class="FontSize"
              v-if="item.attendance_activity_time != undefined"
            >
              {{
                new Date(
                  item.attendance_activity_time * 1000
                ).toLocaleDateString("en-GB")
              }}
            </div>
            <div v-else>-</div>
          </template>
          <template v-slot:[`item.attendance_status`]="{ item }">
            <div>
              <span
                class="red--text FontSize"
                :class="
                  item.attendance_status == 'IN' ? 'green--text' : 'red--text'
                "
                >{{
                  item.attendance_status ? item.attendance_status : "-"
                }}</span
              >
            </div>
            <div class="FontSize" v-if="item.work_status != undefined">
              <div v-if="item.work_status == 'START'" class="green--text">
                WORK STARTED
              </div>
              <div v-else-if="item.work_status == 'END'" class="red--text">
                WORK ENDED
              </div>
              <div
                v-else-if="item.work_status == 'JOURNEY_START'"
                class="yellow--text"
              >
                JOURNEY STARTED
              </div>
              <div
                v-else-if="item.work_status == 'JOURNEY_END'"
                class="orange--text"
              >
                JOURNEY ENDED
              </div>
            </div>
            <div
              v-else-if="
                (item.attendance_status == 'IN' ||
                  item.attendance_status == 'OUT') &&
                item.work_status == undefined
              "
              class="primary--text FontSize"
            >
              IDLE
            </div>
          </template>
          <template v-slot:[`item.login_time`]="{ item }">
            <span class="FontSize"
              >Login :<span v-if="item.login_time != undefined">
                {{ GetLoginTImeMethod(item) }}
              </span>
              <span v-else>--</span>
            </span>
            <div></div>
            <span class="FontSize"
              >Logout :<span v-if="item.logout_time != undefined">
                {{ GetLogOutmethod(item) }}
              </span>
              <span v-else>--</span>
            </span>
          </template>
          <template v-slot:[`item.is_wfh`]="{ item }">
            <div
              class="red--text FontSize"
              :class="item.is_wfh ? 'red--text' : 'green--text'"
            >
              {{ item.is_wfh ? "YES" : "NO" }}
              <v-tooltip
                bottom
                color="primary"
                v-if="item.log_attendance_comment != undefined"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-on="on" v-bind="attrs" color="primary"
                    >mdi-chat-question-outline</v-icon
                  >
                </template>
                <span>{{ item.log_attendance_comment }}</span>
              </v-tooltip>
            </div>
            <div>
              <span class="FontSize"
                >Duration :<span v-if="item.login_duration != undefined">
                  {{ item.login_duration }}
                </span>
                <span v-else>00:00</span>
              </span>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import { GetOrgAttandance } from "@/mixins/OrgAttendance.js";
export default {
  mixins: [GetOrgAttandance],
  props: {
    tab: Number,
    StoreObj: Object,
  },
  components: { Overlay },
  data: () => ({
    menu: false,
    menu1: false,
    overlay: false,
    loading: false,
    CloseValue: false,
    TableHeight: 0,
    search: "",
    attendance_status: "ALL",
    noDataText: "No Data Available..!",
    SelectUser: "ENTERPRISE_REPRESENTATIVE",
    from_date: new Date(new Date().getTime() - 0 * 86400000)
      .toISOString()
      .substr(0, 10),
    to_date: new Date(new Date().getTime() - 0 * 86400000)
      .toISOString()
      .substr(0, 10),
    markers: [],
    GetOrgAttandanceList: [],
    GetDealersList: [],
    GetFilterByUserArray: [],
    GetAllServiceRepresentativeList: [],
    headers: [
      { text: "#", value: "sl_no", tooltip_text: "Serial Number" },
      {
        text: "Name",
        value: "user_name",
        tooltip_text: "User Name",
      },
      {
        text: "Attendance",
        value: "attendance",
        tooltip_text: "Attendence Status",
        align: "center",
      },
      {
        text: "Current Status",
        value: "attendance_status",
        tooltip_text: "Current Status",
        align: "center",
      },
      {
        text: "Login",
        value: "login_time",
        tooltip_text: "Login Time",
      },
      {
        text: "WFH",
        value: "is_wfh",
        tooltip_text: "Is Work From Home ?",
        align: "center",
      },
    ],
    attendance_status_items: [
      { text: "ALL", value: "ALL" },
      { text: "PRESENT", value: "PRESENT" },
      { text: "ABSENT ", value: "ABSENT" },
      { text: "IN", value: "IN" },
      { text: "OUT", value: "OUT" },
    ],
  }),
  mounted() {
    this.TableHeight = window.innerHeight - 200;
    this.GetOrgAttandanceMethod(
      this.StoreObj.organization_id,
      Date.parse(this.from_date) / 1000,
      Date.parse(this.to_date) / 1000 + 86399,
      this.attendance_status == "ALL" ? undefined : this.attendance_status
    );
  },
  methods: {
    ClearFilterMethod() {
      this.CloseValue = true;
      this.attendance_status = "ALL";
      this.SelectUser = "ENTERPRISE_REPRESENTATIVE";
      this.to_date = new Date(new Date().getTime() - 0 * 86400000)
        .toISOString()
        .substr(0, 10);
      this.from_date = new Date(new Date().getTime() - 0 * 86400000)
        .toISOString()
        .substr(0, 10);
      this.GetOrgAttandanceList = [];
      this.GetOrgAttandanceMethod(
        this.StoreObj.organization_id,
        Date.parse(this.from_date) / 1000,
        Date.parse(this.to_date) / 1000 + 86399,
        this.attendance_status == "ALL" ? undefined : this.attendance_status
      );
      setTimeout(() => {
        this.CloseValue = false;
      }, 1000);
    },
    GetLoginTImeMethod(item) {
      let LOGINTIME = new Date(item.login_time * 1000).toString().slice(15, 21);
      return LOGINTIME;
    },
    GetLogOutmethod(item) {
      let LOGOUTTIME = new Date(item.logout_time * 1000)
        .toString()
        .slice(15, 21);
      return LOGOUTTIME;
    },
    SortByDateMethod() {
      this.CloseValue = true;
      this.GetOrgAttandanceList = [];
      this.GetOrgAttandanceMethod(
        this.StoreObj.organization_id,
        Date.parse(this.from_date) / 1000,
        Date.parse(this.to_date) / 1000 + 86399,
        this.attendance_status == "ALL" ? undefined : this.attendance_status
      );
      setTimeout(() => {
        this.CloseValue = false;
      }, 1000);
    },
  },
};
</script>
