<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent width="400px" v-model="rechargedialog">
      <v-card>
        <v-toolbar class="primary elevation-0" dense dark>
          <v-toolbar-title class="ml-2 white--text">Recharge</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            large
            class="mr-n4"
            dark
            text
            @click="rechargedialogEmit((Toggle = 1))"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pb-0">
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="12" md="12" xs="12" class="mt-6">
                <v-text-field
                  color="negative"
                  class="FontSize field_label_size field_height"
                  :rules="[(v) => !!v || 'Required']"
                  type="number"
                  dense
                  required
                  outlined
                  v-model="rechargeamout"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            small
            :loading="isLoading"
            @click="validateMethod"
            class="mr-2 primary white--text"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
import { ReloadCreditsOfEnterprise } from "@/graphql/mutations.js";
export default {
  props: {
    StoreObj: Object,
    rechargedialog: Boolean,
  },
  components: {
    Snackbar,
  },
  data() {
    return {
      Toggle: 0,
      rechargeamout: 0,
      isLoading: false,
      SnackBarComponent: {},
    };
  },
  watch: {
    rechargedialog(val) {
      if (val == true) {
        this.rechargeamout = 0;
      }
    },
  },
  methods: {
    validateMethod() {
      if (this.$refs.form.validate()) {
        this.addRecharge();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fill all the required details",
        };
      }
    },
    async addRecharge() {
      this.isLoading = true;
      try {
        var inputParams = {
          organization_id: this.StoreObj.organization_id,
          ticket_credits: this.rechargeamout,
        };
        let result = await API.graphql(
          graphqlOperation(ReloadCreditsOfEnterprise, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.ReloadCreditsOfEnterprise);
        if (ResultObject.status == "SUCCESS") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.rechargedialogEmit((this.Toggle = 2));
        }
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
      }
    },
    rechargedialogEmit(Toggle) {
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
<style>
#card {
  border: 1px solid;
}
</style>
