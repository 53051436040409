<template>
  <div>
    <v-toolbar dense class="elevation-0">
      <h3>
        <span v-if="StoreObj.organization_name"
          >{{ StoreObj.organization_name }}'s</span
        >
        Users
      </h3>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  props: {
    StoreObj: Object,
  },
};
</script>

<style></style>
