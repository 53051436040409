<template>
  <div>
    <Overlay :overlay="overlay" />
    <v-toolbar dense class="elevation-0">
      <h3>{{ StoreObj.organization_name }}'s Bulk Upload Logs</h3>
      <v-spacer />
      <v-text-field
        dense
        outlined
        label="Search"
        class="mt-7 mr-1 FontSize maxWidthXSmall field_label_size field_height"
        v-model="search"
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
      <v-select
        dense
        outlined
        v-model="log_type"
        label="Uploaded Type"
        :items="log_type_items"
        class="mt-7 mr-1 FontSize maxWidthSmall field_label_size field_height"
      >
      </v-select>
    </v-toolbar>
    <v-card class="elevation-0">
      <v-card-text>
        <v-data-table
          dense
          :search="search"
          :headers="headers"
          :height="TableHeight"
          :no-data-text="noDataText"
          :items="BulkUploadLogItems"
        >
          <template v-slot:[`item.log_type`]="{ item }">
            <div v-if="item.log_type">
              {{ item.log_type.replaceAll("_", " ") }}
            </div>
          </template>
          <template v-slot:[`item.logged_on`]="{ item }">
            <div v-if="item.logged_on">
              {{ new Date(item.logged_on * 1000).toLocaleString("en-GB") }}
            </div>
          </template>
          <template v-slot:[`item.logger_email_id`]="{ item }">
            <div v-if="item.logger_email_id">
              {{ item.logger_email_id }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import { GetBulkUploadLogs } from "@/mixins/GetBulkUploadLogs.js";
export default {
  props: {
    StoreObj: Object,
  },
  components: {
    Overlay,
  },
  mixins: [GetBulkUploadLogs],
  data: () => ({
    search: "",
    noDataText: "",
    log_type: "ALL",
    overlay: false,
    TableHeight: 0,
    log_type_items: [
      { text: "ALL", value: "ALL" },
      { text: "CUSTOMER", value: "CUSTOMER_BULK_UPLOAD" },
      { text: "PRODUCT FLEET", value: "PRODUCT_FLEET_BULK_UPLOAD" },
      {
        text: "SERVICE PARTNER",
        value: "SERVICE_PARTNER_BULK_UPLOAD",
      },
      {
        text: "COMPONENT INVENTORY",
        value: "COMPONENT_INVENTORY_BULK_UPLOAD",
      },
      { text: "DEALERS", value: "DEALERS_BULK_UPLOAD" },
      {
        text: "ENTERPRISE REPRESENTATIVE",
        value: "ENTERPRISE_REPRESENTATIVE_BULK_UPLOAD",
      },
      {
        text: "PRODUCT TAGGING",
        value: "PRODUCT_TAGGING_BULK_UPLOAD",
      },
      { text: "COMPONENTS", value: "COMPONENTS_BULK_UPLOAD" },
      { text: "TERRITORY", value: "TERRITORY_BULK_UPLOAD" },
      { text: "USERS", value: "USERS_BULK_UPLOAD" },
    ],
    BulkUploadLogItems: [],
    headers: [
      { text: "Uploaded Type", value: "log_type" },
      { text: "Uploaded On", value: "logged_on" },
      { text: "Uploader Mail ID", value: "logger_email_id" },
      { text: "Uploader Name", value: "logged_by" },
      { text: "Uploaded Status", value: "log_action" },
    ],
  }),
  watch: {
    log_type(val) {
      if(val != 'ALL') {
        this.BulkUploadLogItemsMethod(this.StoreObj.organization_id, val);
      } else {
        this.BulkUploadLogItemsMethod(this.StoreObj.organization_id, undefined);
      }
    }
  },
  mounted() {
    this.TableHeight = window.innerHeight - 200;
    this.BulkUploadLogItemsMethod(this.StoreObj.organization_id, undefined);
  },
};
</script>

<style></style>
