import { Get_Enterprise_Details } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllOrganizationSettingsTypesInMS = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      time_setting: {},
      GetAllOrganizationSettingsTypesObject: {},
    };
  },
  methods: {
    async GetAllOrganizationSettingsTypesMethod(organization_id) {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(Get_Enterprise_Details, {
            input: {
                organization_id: organization_id
            },
          })
        );
        this.GetAllOrganizationSettingsTypesObject = JSON.parse(
          result.data.Get_Enterprise_Details
        ).data.items;
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.GetAllOrganizationSettingsTypesObject = {};
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
