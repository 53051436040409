<template>
  <div>
    <Overlay :overlay="overlay" />
    <AddOrganization
      :AddOrgdialog="AddOrgdialog"
      :StoreObj="StoreObj"
      @clicked="AddOrgdialogEmit"
    />
    <SetOTPDialog
      :usersDialog="usersDialog"
      :StoreObj="StoreObj"
      @clicked="usersDialogEmit"
    />
    <DeleteOrgDialog
      :dialogDeleteOrg="dialogDeleteOrg"
      :StoreObj="StoreObj"
      @clicked="DeleteOrgEmit"
    />
    <RechargeEnterprise
      :StoreObj="StoreObj"
      :rechargedialog="rechargedialog"
      @clicked="rechargedialogEmit"
    />
    <OrgDetailsTabs
      :StoreObj="StoreObj"
      v-if="current_view == 'TABS'"
      @clicked="emit_tab"
    />
    <v-card class="ma-1" flat elevation="0" v-if="current_view == 'TABLE'">
      <v-toolbar dense class="elevation-0">
        <div class="Primary FontColor heading1">Organizations</div>
        <v-spacer />
        <v-select
          dense
          outlined
          label="Status"
          class="
            mt-9
            mr-2
            maxWidthXSmall
            FontSize
            field_label_size field_height
          "
          v-model="organization_status"
          :items="StatusItems"
        ></v-select>
        <v-select
          dense
          outlined
          label="Filter"
          class="
            mt-9
            mr-2
            maxWidthXSmall
            FontSize
            field_label_size field_height
          "
          v-model="organization_filter"
          :items="categories"
        ></v-select>
        <v-text-field
          dense
          outlined
          label="Search"
          class="mt-9 FontSize maxWidthXSmall field_label_size field_height"
          v-model="search"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
        <v-tooltip color="primary" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              @click="(AddOrgdialog = true), (StoreObj.action = 'CREATE')"
              v-on="on"
              v-bind="attrs"
              class="ml-2 mt-3 primary white--text"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </template>
          <span class="white--text">Add Organization</span>
        </v-tooltip>
      </v-toolbar>
    </v-card>
    <v-card class="ma-1" flat elevation="0" v-if="current_view == 'TABLE'">
      <v-card-text class="py-0">
        <v-data-table
          class="dtwidth"
          dense
          :search="search"
          :headers="headers"
          :items="GetAllOrganizationsList"
          :height="tableHeight"
          style="cursor: pointer"
          fixed-header
          @click:row="handleRowClick"
          :footer-props="{
            'items-per-page-options': [100, 200, 500],
          }"
          :no-data-text="noDataText"
          :items-per-page="100"
          :item-class="row_classes"
        >
          <template v-slot:[`item.active_tickets`]="{ item }">
            <span
              v-if="item.active_tickets != undefined"
              class="overflow FontSize"
              >{{ item.active_tickets }}</span
            >
            <span v-else class="overflow FontSize">0</span>
          </template>
          <template v-slot:[`item.closed_tickets`]="{ item }">
            <span
              v-if="item.closed_tickets != undefined"
              class="overflow FontSize"
              >{{ item.closed_tickets }}</span
            >
            <span v-else class="overflow FontSize">0</span>
          </template>
          <template v-slot:[`item.overall_tickets`]="{ item }">
            <span
              v-if="item.overall_tickets != undefined"
              class="overflow FontSize"
              >{{ item.overall_tickets }}</span
            >
            <span v-else class="overflow FontSize">0</span>
          </template>
          <template v-slot:[`item.organization_ticket_prefix`]="{ item }">
            <div
              class="FontSize"
              v-if="item.organization_ticket_prefix != undefined"
            >
              {{ item.organization_ticket_prefix }}
            </div>
            <div
              class="FontSize"
              v-if="item.organization_created_on != undefined"
            >
              {{
                new Date(
                  item.organization_created_on * 1000
                ).toLocaleDateString("en-GB")
              }}
            </div>
          </template>
          <template v-slot:[`item.organization_name`]="{ item }">
            <div class="FontSize" v-if="item.organization_name != undefined">
              {{ item.organization_name }}
            </div>
            <div
              class="FontSize"
              v-if="item.organization_person_email_id != undefined"
            >
              {{ item.organization_person_email_id }}
            </div>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-menu right bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon color="primary">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense nav>
                <v-list-item-group color="primary">
                  <v-list-item
                    v-if="item.organization_status == 'ACTIVE'"
                    @click="checkItem(item, 'EDIT')"
                  >
                    <v-list-item-icon>
                      <v-icon small color="primary" class="ml-n4"
                        >mdi-pencil-plus</v-icon
                      >
                    </v-list-item-icon>
                    <span class="overflow FontSize ml-n10">EDIT</span>
                  </v-list-item>
                  <v-list-item
                    v-if="item.organization_status == 'ACTIVE'"
                    @click="checkItem(item, 'DELETE')"
                  >
                    <v-list-item-icon>
                      <v-icon small class="red--text ml-n4"
                        >mdi-close-thick</v-icon
                      >
                    </v-list-item-icon>
                    <span class="overflow FontSize ml-n10">DEACTIVATE</span>
                  </v-list-item>
                  <v-list-item
                    v-if="item.organization_status == 'ACTIVE'"
                    @click="checkItem(item, 'SET_OTP')"
                  >
                    <v-list-item-icon>
                      <v-icon small class="green--text ml-n4"
                        >mdi-lock-check</v-icon
                      >
                    </v-list-item-icon>
                    <span class="overflow FontSize ml-n10">SET OTP</span>
                  </v-list-item>
                  <v-list-item
                    v-if="item.organization_status == 'INACTIVE'"
                    @click="checkItem(item, 'DELETE')"
                  >
                    <v-list-item-icon>
                      <v-icon small class="green--text ml-n4">mdi-check</v-icon>
                    </v-list-item-icon>
                    <span class="overflow FontSize ml-n10">ACTIVATE</span>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:[`item.organization_created_on`]="{ item }">
            <div
              v-if="item.organization_created_on != undefined"
              class="FontSize"
            >
              {{
                new Date(
                  item.organization_created_on * 1000
                ).toLocaleDateString("en-GB")
              }}
            </div>
          </template>
          <template v-slot:[`item.ticket_credits`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <span class="FontSize" v-on="on" v-if="item.ticket_credits"
                  ><v-icon
                    @click="checkItem(item, 'RECHARGE')"
                    small
                    color="primary"
                    class="mr-2"
                    >mdi-currency-usd</v-icon
                  >{{ item.ticket_credits }}
                </span>
                <span class="FontSize" v-else
                  ><v-icon small color="primary" class="mr-2"
                    >mdi-currency-usd</v-icon
                  >0</span
                >
              </template>
              <span class="white--text FontSize">Recharge Credits</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.pricing_model`]="{ item }">
            <span class="FontSize" v-if="item.pricing_model"
              >{{ item.pricing_model.replace("_", " ") }}
            </span>
            <span v-else>-</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { GetAllOrganizations } from "@/mixins/GetAllOrganizations.js";
import Overlay from "@/components/Extras/Overlay.vue";
import SetOTPDialog from "@/components/Organizations/Dialogs/SetOTPDialog.vue";
import OrgDetailsTabs from "@/components/Organizations/Cards/OrgDetailsTabs.vue";
import AddOrganization from "@/components/Organizations/Dialogs/AddOrganization.vue";
import DeleteOrgDialog from "@/components/Organizations/Dialogs/DeleteOrgDialog.vue";
import RechargeEnterprise from "@/components/Organizations/Dialogs/RechargeEnterprise.vue";
export default {
  components: {
    Overlay,
    SetOTPDialog,
    OrgDetailsTabs,
    AddOrganization,
    DeleteOrgDialog,
    RechargeEnterprise,
  },
  mixins: [GetAllOrganizations],
  data: () => ({
    tableHeight: 0,
    usersDialog: false,
    AddOrgdialog: false,
    rechargedialog: false,
    dialogDeleteOrg: false,
    search: "",
    current_view: "TABLE",
    organization_filter: "ALL",
    organization_status: "ACTIVE",
    GetAllOrganizationsList: [],
    GetAllOrganizationsListFilter: [],
    StatusItems: ["ACTIVE", "INACTIVE"],
    categories: ["ALL", "PARTNER", "ENTERPRISE"],
    headers: [
      { text: "Name", value: "organization_name", width: "22%" },
      {
        text: "Ticket Prefix",
        value: "organization_ticket_prefix",
        width: "12%",
      },
      { text: "Pricing Model", value: "pricing_model", width: "12%" },
      {
        text: "Active Tkts",
        value: "active_tickets",
        width: "10%",
      },
      {
        text: "Close Tkts",
        value: "closed_tickets",
        width: "10%",
      },
      {
        text: "Total Tkts",
        value: "overall_tickets",
        width: "10%",
      },
      { text: "Credits", value: "ticket_credits", width: "10%" },
      { text: "Actions", value: "Actions", width: "9%" },
    ],
    StoreObj: {},
  }),
  watch: {
    organization_status() {
      this.GetAllOrganizationMethod();
    },
    organization_filter(val) {
      if (val == "ALL") {
        this.GetAllOrganizationsList = this.GetAllOrganizationsListFilter;
      } else {
        this.GetAllOrganizationsList =
          this.GetAllOrganizationsListFilter.filter(
            (item) => item.organization_type == val
          );
      }
      if (this.GetAllOrganizationsList.length == 0) {
        this.noDataText = `No Organization Found With The Category ${this.organization_filter}`;
      }
    },
  },
  mounted() {
    this.GetAllOrganizationMethod();
    this.tableHeight = window.innerHeight - 200;
  },
  methods: {
    row_classes(item) {
      if (item.is_actual_client == true) {
        return "secondary lighten-4";
      }
    },
    handleRowClick(item) {
      this.StoreObj = item;
      this.current_view = "TABS";
    },
    rechargedialogEmit(Toggle) {
      this.rechargedialog = false;
      if (Toggle == 2) {
        this.GetAllOrganizationMethod();
      }
    },
    // logs_page_emit(Toggle) {
    //   this.current_view = 'TABLE'
    // },
    emit_tab(Toggle) {
      this.current_view = "TABLE";
    },
    DeleteOrgEmit(Toggle) {
      this.dialogDeleteOrg = false;
      if (Toggle == 2) {
        this.GetAllOrganizationMethod();
      }
    },
    AddOrgdialogEmit(Toggle) {
      this.AddOrgdialog = false;
      if (Toggle == 2) {
        this.GetAllOrganizationMethod();
      }
    },
    usersDialogEmit(Toggle) {
      this.usersDialog = false;
      if (Toggle == 2) {
        this.GetAllOrganizationMethod();
      }
    },
    checkItem(item, action) {
      this.StoreObj = item;
      if (action == "DELETE") {
        this.dialogDeleteOrg = true;
      } else if (action == "EDIT") {
        this.StoreObj.action = "EDIT";
        this.AddOrgdialog = true;
      } else if (action == "RECHARGE") {
        this.rechargedialog = true;
      } else if (action == "SET_OTP") {
        this.usersDialog = true;
      }
    },
    // editOrganization(item) {
    //   this.$refs.EditOrganization.EditOrgdialog = true;
    //   var orgdetails = {
    //     organization_id: item.organization_id,
    //     organization_name: item.organization_name,
    //     organization_type: item.organization_type,
    //     organization_person_email_id: item.organization_person_email_id,
    //     organization_ticket_prefix: item.organization_ticket_prefix,
    //     ticket_credits: item.ticket_credits,
    //   };
    //   this.$refs.EditOrganization.getOrgdetails(orgdetails);
    // },
    // callmethoddatetime(organization_created_on) {
    //   let a = new Date(organization_created_on * 1000).toLocaleDateString(
    //     "en-In",
    //     {
    //       year: "numeric",
    //       month: "2-digit",
    //       day: "2-digit",
    //     }
    //   );
    //   return a;
    // },
  },
};
</script>

<style scoped>
.dtwidth {
  text-overflow: ellipsis;
}
.gradient-btn {
  background: -webkit-linear-gradient(#7b046c 40%, #bd58b0 60%);
  -webkit-text-fill-color: transparent;
}
.gradient-bg {
  background: -webkit-linear-gradient(145deg, #bd58b0 0%, #7b046c 100%);
  background: -moz-linear-gradient(145deg, #bd58b0 0%, #7b046c 100%);
  background: -o-linear-gradient(145deg, #bd58b0 0%, #7b046c 100%);
  background: -ms-linear-gradient(145deg, #bd58b0 0%, #7b046c 100%);
  background: linear-gradient(145deg, #bd58b0 0%, #7b046c 100%);
}
.gradient-bg2 {
  background: -webkit-linear-gradient(145deg, #7b046c 0%, #bd58b0 100%);
  background: -moz-linear-gradient(145deg, #7b046c 0%, #bd58b0 100%);
  background: -o-linear-gradient(145deg, #7b046c 0%, #bd58b0 100%);
  background: -ms-linear-gradient(145deg, #7b046c 0%, #bd58b0 100%);
  background: linear-gradient(145deg, #7b046c 0%, #bd58b0 100%);
}

.orange {
  background-color: #bd58b0;
}
</style>
