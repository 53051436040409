<template>
  <div>
    <v-tabs
      class="ma-2"
      v-model="tab"
      color="primary"
      background-color="transparent"
      style="border-bottom: solid 1px gray"
    >
      <v-tab>Organization Details</v-tab>
      <v-tab>Tickets</v-tab>
      <v-tab>Users</v-tab>
      <v-tab>Bulk Upload Logs</v-tab>
      <v-tab>Attendance</v-tab>
      <v-tab>Organization Settings</v-tab>
      <v-toolbar dense class="elevation-0">
        <v-spacer /><v-spacer />
        <v-btn small color="primary white--text" @click="EmitTab()"
          ><v-icon>mdi-chevron-double-left</v-icon>Back</v-btn
        >
      </v-toolbar>
    </v-tabs>
    <v-card v-if="tab == 0" class="elevation-0">
      <OrganizationDetails :StoreObj="StoreObj" :tab="tab" />
    </v-card>
    <v-card v-if="tab == 1" class="elevation-0">
      <OrgTicketDetails :StoreObj="StoreObj" :tab="tab" />
    </v-card>
    <v-card v-if="tab == 2" class="elevation-0">
      <OrgUsers :StoreObj="StoreObj" :tab="tab" />
    </v-card>
    <v-card v-if="tab == 3" class="elevation-0">
      <ViewOrgBulkUploadLogs :StoreObj="StoreObj" :tab="tab" />
    </v-card>
    <v-card v-if="tab == 4" class="elevation-0">
      <OrgAttendanceDetails :StoreObj="StoreObj" :tab="tab" />
    </v-card>
    <v-card v-if="tab == 5" class="elevation-0">
      <OrgSettings :StoreObj="StoreObj" :tab="tab" />
    </v-card>
  </div>
</template>
<script>
import OrgUsers from "@/components/Organizations/Cards/OrgUsers.vue";
import OrgSettings from "@/components/Organizations/Cards/OrgSettings.vue";
import OrgTicketDetails from "@/components/Organizations/Cards/OrgTicketDetails.vue";
import OrganizationDetails from "@/components/Organizations/Cards/OrganizationDetails.vue";
import OrgAttendanceDetails from "@/components/Organizations/Cards/OrgAttendanceDetails.vue";
import ViewOrgBulkUploadLogs from "@/components/Organizations/Cards/ViewOrgBulkUploadLogs.vue";
export default {
  props: {
    StoreObj: Object,
  },
  components: {
    OrgUsers,
    OrgSettings,
    OrgTicketDetails,
    OrganizationDetails,
    OrgAttendanceDetails,
    ViewOrgBulkUploadLogs,
  },
  data() {
    return {
      tab: 0,
    };
  },
  methods: {
    EmitTab() {
      this.$emit("clicked");
    },
  },
};
</script>

<style></style>
