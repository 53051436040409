<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="usersDialog" width="30%">
      <v-card>
        <v-toolbar dense dark class="white--text primary"
          >Set Custom OTP
          <v-spacer />
          <v-btn icon @click="usersDialogEmit(1)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-form ref="form">
            <v-row>
              <v-col cols="12" md="6" sm="12" xs="12">
                <div class="font-weight-bold mt-5">Custom OTP :</div>
              </v-col>
              <v-col cols="12" md="6" xs="12" sm="12">
                <v-text-field
                  dense
                  outlined
                  :rules="otp_rules"
                  v-model="custom_opt"
                  label="Custom OTP *"
                  class="FontSize mt-3"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            small
            :loading="loading_btn"
            @click="setCustomOTP()"
            class="primary white--text mb-4 mr-2"
            >Set</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { API, graphqlOperation } from "aws-amplify";
import { UpdateOtpForUsers } from "@/graphql/mutations.js";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    StoreObj: Object,
    usersDialog: Boolean,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    custom_opt: "",
    loading_btn: false,
    SnackBarComponent: {},
    otp_rules: [
      (v) =>
        /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9 +-]{0,64}$/.test(v) || "Invalid",
      (v) => (v && v.length < 7) || "Invalid",
      (v) => (v && v.length > 5) || "Invalid",
    ],
  }),
  methods: {
    usersDialogEmit(Toggle) {
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
    async setCustomOTP() {
      if (this.$refs.form.validate()) {
        this.loading_btn = true;
        try {
          var inputParams = {
            updater_user_email_id:
              this.$store.getters.get_current_user_details.user_email_id,
            email_notification: false,
            custom_opt: this.custom_opt,
          };
          let result = await API.graphql(
            graphqlOperation(UpdateOtpForUsers, {
              input: inputParams,
            })
          );
          var ResultObject = JSON.parse(result.data.UpdateOtpForUsers);
          if (ResultObject.status == "SUCCESS") {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: ResultObject.Status_Message,
            };
            this.usersDialogEmit(2);
          }
          this.loading_btn = false;
        } catch (error) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: error.errors[0].message,
          };
          this.loading_btn = false;
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Enter Custom OTP..!",
        };
      }
    },
  },
};
</script>
