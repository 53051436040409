<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent width="80%" v-model="AddOrgdialog">
      <v-card>
        <v-toolbar class="fixed-bar primary" dense dark>
          <v-toolbar-title class="ml-2 white--text">{{
            StoreObj.action == "CREATE"
              ? "Add Organization"
              : "Edit Organization"
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              large
              class="mr-n4"
              dark
              text
              @click="AddOrgdialogEmit((Toggle = 1))"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="mt-4 pb-0">
          
          <v-form ref="form">
            <v-row>
              <v-col cols="12" md="3" xs="12" sm="12">
                <v-select
                  :items="orgtypes"
                  outlined
                  dense
                  label="Organization Type *"
                  class="FontSize field_label_size field_height"
                  v-model="organization_type"
                  :rules="[(v) => !!v || 'Required']"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3" xs="12" sm="12">
                <v-text-field
                  outlined
                  dense
                  label="Organization Name *"
                  class="FontSize field_label_size field_height"
                  v-model="organization_name"
                  :rules="[
                    (v) => !!v || 'Required',
                    (v) =>
                      /^(?=[ a-z A-Z 0-9 & @._%+-]{0,100}$)[& a-z A-Z 0-9]{0,100}$/.test(
                        v
                      ) ||
                      'Invalid' ||
                      'Invalid Value',
                  ]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" xs="12" sm="12">
                <v-autocomplete
                  label="Country Name *"
                  v-model="country_name"
                  :items="CountryList"
                  item-value="name"
                  item-text="name"
                  dense
                  outlined
                  class="FontSize field_label_size field_height"
                  :rules="[((v) => !!v) || 'Required']"
                >
                  <template slot="selection" slot-scope="{ item }">
                    {{ item.name }}
                  </template>
                  <template slot="item" slot-scope="{ item }">
                    {{ item.name }}</template
                  >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" xs="12" sm="12">
                <v-text-field
                  outlined
                  dense
                  label="Email ID *"
                  class="FontSize field_label_size field_height"
                  :readonly="StoreObj.action == 'EDIT'"
                  v-model="organization_person_email_id"
                  :rules="[
                    (v) => !!v || 'Required',
                    (v) => /.+@.+/.test(v) || 'Invalid',
                  ]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" xs="12" sm="12">
                <v-text-field
                  outlined
                  dense
                  label="Onboarding price *"
                  class="FontSize field_label_size field_height"
                  v-model="organization_cost"
                  :rules="[
                    (v) => !!v || 'Required',
                    (v) =>
                      /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9 +-]{0,64}$/.test(v) ||
                      'numbers only',
                  ]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" xs="12" sm="12">
                <v-text-field
                  hint="Should be 3 Character"
                  outlined
                  dense
                  label="Ticket Prefix *"
                  class="FontSize field_label_size field_height"
                  :rules="[
                    (v) => !!v || 'Required',
                    (v) => (v && v.length > 2) || 'Invalid Value',
                    (v) => (v && v.length <= 3) || 'Invalid Value',
                  ]"
                  v-model="organization_ticket_prefix"
                  @keyup="callmethodUppercase(organization_ticket_prefix)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" xs="12" sm="12">
                <span class="ml-3 mt-3">Ticket Type :</span>
              </v-col>
              <v-col cols="12" md="2" xs="12" sm="12">
                <v-checkbox
                  label="Floating Ticket"
                  v-model="TicketsType"
                  class="mt-0 ml-2"
                  value="FLOATING_TICKET"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="12" md="2" xs="12" sm="12">
                <v-checkbox
                  label="Assigned Ticket"
                  v-model="TicketsType"
                  class="mt-0 ml-2"
                  value="ASSIGNED_TICKET"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="12" md="3" xs="12" sm="12">
                <v-checkbox
                  label="Actual Client"
                  v-model="is_actual_client"
                  class="mt-1 ml-1"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="12" md="3" xs="12" sm="12">
                <v-checkbox
                  label="Enable Co-Ordinator App"
                  v-model="enablecoordinator"
                  class="mt-0 ml-1"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="12" md="3" xs="12" sm="12">
                <v-checkbox
                  label="Enable Credit Recharge"
                  v-model="enablecreditRecharge"
                  class="mt-0 ml-1"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="12" md="3" xs="12" sm="12">
                <v-checkbox
                  label="Enable Switch Organization"
                  v-model="enableswitchOrganization"
                  class="mt-0 ml-1"
                >
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" sm="12" xs="12">
                <v-select
                  outlined
                  dense
                  label="Pricing Model *"
                  class="FontSize field_label_size field_height"
                  v-model="pricing_model"
                  :items="pricing_model_List"
                >
                </v-select>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="6"
                v-if="pricing_model == 'FLAT_FEE'"
              >
                <v-text-field
                  persistent-hint
                  outlined
                  dense
                  label="Monthly Cost"
                  class="FontSize field_label_size field_height"
                  v-model="monthly_cost"
                  :rules="[(v) => !!v || 'Required']"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                xs="12"
                v-if="pricing_model == 'USERS'"
              >
                <v-text-field
                  persistent-hint
                  outlined
                  dense
                  label="Monthly Cost *"
                  class="FontSize field_label_size field_height"
                  v-model="monthly_cost"
                  :rules="[(v) => !!v || 'Required']"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                xs="12"
                v-if="pricing_model == 'SERVICE_CREDITS'"
              >
                <v-text-field
                  hint="Format 10,20,30,.."
                  persistent-hint
                  outlined
                  dense
                  :label="
                    organization_type == 'PARTNER'
                      ? 'Ticket Credits for each Representative *'
                      : 'Ticket Credits For Enterprise*'
                  "
                  class="FontSize field_label_size field_height"
                  v-model="ticket_credits"
                  :rules="[
                    (v) => !!v || 'Required',
                    (v) =>
                      (v > 0 && v <= organization_type == 'PARTNER'
                        ? 100
                        : 1000) || 'Invalid Value',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-card outlined v-if="pricing_model == 'SERVICE_CREDITS'">
              <span class="ml-3 mt-2">Credit Per Model *</span>
              <v-row
                class="mt-2"
                v-if="
                  Creditpermodule.length != 0 || Creditpermodule != undefined
                "
              >
                <v-col
                  v-for="(opn, idx) in Creditpermodule"
                  :key="idx"
                  cols="12"
                  xs="12"
                  sm="12"
                  md="4"
                  class="py-0"
                  dense
                >
                  <v-card-actions class="pa-0">
                    <v-col  cols="12" xs="12" sm="12" md="8" >
                      <v-checkbox
                        dense
                        class="mt-0 mb-n4 ml-1"
                        v-model="opn.is_enabled"
                        value: opn.module_name
                        :label="opn.module_name"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4">
                      <v-text-field
                        dense
                        outlined
                        :disabled="opn.disabled"
                        label="Credits *"
                        class="
                          FontSize
                          field_label_size field_height
                          mt-0
                          mb-n4
                          ml-1
                        "
                        v-model="opn.credits_charge"
                        :rules="[
                          (v) => !!v || 'Required',
                          (v) =>
                            /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9 +-]{0,64}$/.test(
                              v
                            ) || 'numbers only',
                        ]"
                      ></v-text-field>
                    </v-col>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card>
            <v-row>
              <v-col cols="12" md="3" xs="12" sm="12">
                <v-checkbox
                  v-model="whatsapp_notification"
                  label="Enable Whatsapp Notifications"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="12" md="3" xs="12" sm="12">
                <v-autocomplete
                  outlined
                  dense
                  v-if="whatsapp_notification == true"
                  :rules="
                    whatsapp_notification == true
                      ? [(v) => !!v || 'Required']
                      : []
                  "
                  v-model="whatsapp_notification_type"
                  label="Notification From"
                  class="FontSize mt-4"
                  :items="whatsapp_notification_type_items"
                ></v-autocomplete>
              </v-col>
              <!-- <v-col cols="12" md="3" xs="12" sm="12">
                <v-autocomplete
                  outlined
                  dense
                  v-model="sms_provider"
                  label="SMS Provider"
                  class="FontSize mt-4"
                  :items="sms_provider_items"
                >
                </v-autocomplete>
              </v-col> -->
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="3" xs="12" sm="12">
                <v-checkbox
                  v-model="is_enable_bulk_upload"
                  label="Enable Bulk Upload"
                >
                </v-checkbox>
              </v-col>
              <v-col
                cols="12"
                md="3"
                xs="12"
                sm="12"
                v-if="is_enable_bulk_upload == true"
              >
                <v-autocomplete
                  dense
                  outlined
                  multiple
                  class="FontSize mt-4"
                  label="Bulk Upload For"
                  :items="Bulk_Upload_Items"
                  v-model="approved_bulk_upload"
                  :rules="
                    is_enable_bulk_upload == true
                      ? [(v) => !!v.length || 'Required']
                      : []
                  "
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="3" xs="12" sm="12">
                <v-checkbox
                v-model="enableQuotGen"
                    label="Enable Quotation Generation"
                    class="FontSize"
                  >
                  </v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="StoreObj.action == 'EDIT'">
              <v-col cols="12" lg="4" md="4" xs="12" sm="12">
                <v-card
                  :color="
                    enable_raydeo_dashboard_notification == true
                      ? '#E0E0E0'
                      : ''
                  "
                  class="elevation-0"
                >
                  <v-checkbox
                    v-model="enable_raydeo_dashboard_notification"
                    label="Enable Raydeo Dashboard Notification"
                    class="ma-2"
                  >
                  </v-checkbox>
                  <v-text-field
                    v-if="enable_raydeo_dashboard_notification == true"
                    outlined
                    dense
                    label="Dashboard Channel API Key"
                    class="mx-3 FontSize"
                    v-model="dashboard_channel_api_key"
                    :rules="
                      enable_raydeo_dashboard_notification == true
                        ? [(v) => !!v || 'Required']
                        : []
                    "
                  >
                  </v-text-field>
                  <v-text-field
                    v-if="enable_raydeo_dashboard_notification == true"
                    outlined
                    dense
                    label="Dashboard X API Key"
                    class="mx-3 FontSize"
                    v-model="dashboard_x_api_key"
                    :rules="
                      enable_raydeo_dashboard_notification == true
                        ? [(v) => !!v || 'Required']
                        : []
                    "
                  >
                  </v-text-field>
                </v-card>
              </v-col>
              <v-col cols="12" lg="4" md="4" xs="12" sm="12">
                <v-card
                  :color="
                    enable_raydeo_ticket_trail_notification == true
                      ? '#E0E0E0'
                      : ''
                  "
                  class="elevation-0"
                >
                  <v-checkbox
                    v-model="enable_raydeo_ticket_trail_notification"
                    label="Enable Raydeo Ticket Trail Notification"
                    class="ma-2 FontSize"
                  >
                  </v-checkbox>
                  <v-text-field
                    v-if="enable_raydeo_ticket_trail_notification == true"
                    outlined
                    dense
                    label="Ticket Trial Channel API Key"
                    v-model="ticket_trail_channel_api_key"
                    class="mx-3 FontSize"
                    :rules="
                      enable_raydeo_ticket_trail_notification == true
                        ? [(v) => !!v || 'Required']
                        : []
                    "
                  >
                  </v-text-field>
                  <v-text-field
                    v-if="enable_raydeo_ticket_trail_notification == true"
                    class="mx-3 FontSize"
                    outlined
                    dense
                    label="Ticket Trail X API Key"
                    v-model="ticket_trail_x_api_key"
                    :rules="
                      enable_raydeo_ticket_trail_notification == true
                        ? [(v) => !!v || 'Required']
                        : []
                    "
                  >
                  </v-text-field>
                </v-card>
              </v-col>
         <!--    <v-col cols="12" lg="4" md="4" xs="12" sm="12">
                <v-checkbox
                v-model="enableQuotGen"
                    label="Enable Quotation Generation"
                    class="ma-2 FontSize"
                  >
                  </v-checkbox>
              </v-col>
            -->
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            small
            :loading="isLoading"
            @click="validateaddOrgs"
            class="mr-2 mb-4 primary white--text"
            ><span v-text="StoreObj.action == 'CREATE' ? 'ADD' : 'EDIT'"></span
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { API, graphqlOperation } from "aws-amplify";
import {
  Create_Organization,
  Update_Organization,
} from "@/graphql/mutations.js";
import CountryList from "@/JsonFiles/CountryList.json";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  components: {
    Snackbar,
  },
  props: {
    StoreObj: Object,
    AddOrgdialog: Boolean,
    GetCurrentUserDetailsObj: Object,
  },
  data: () => ({
    Toggle: 0,
    monthly_cost: 0,
    monthly_cost1: 0,
    ticket_credits: 0,
    ticket_creditsEnterprise: 0,
    sms_provider: "",
    country_name: "",
    pricing_model: "",
    ActiveTicketcount: "",
    organization_name: "",
    organization_cost: "",
    dashboard_x_api_key: "",
    organization_category: "",
    ticket_trail_x_api_key: "",
    dashboard_channel_api_key: "",
    organization_ticket_prefix: "",
    whatsapp_notification_type: "",
    organization_type: "ENTERPRISE",
    organization_person_email_id: "",
    ticket_trail_channel_api_key: "",
    checkbox: false,
    isLoading: false,
    enablecoordinator: true,
    is_actual_client: false,
    enablecreditRecharge: false,
    is_enable_bulk_upload: false,
    whatsapp_notification: false,
    enableswitchOrganization: false,
    enable_raydeo_dashboard_notification: false,
    enable_raydeo_ticket_trail_notification: false,
    categories: [],
    TicketsType: [],
    CountryList: [],
    OrganizationsList: [],
    approved_bulk_upload: [],
    sms_provider_items: [
    { text: "Soft SMS", value: "SOFT_SMS"},
    { text: "Option 2", value: "OPTION_TWO"}
    ],
    whatsapp_notification_type_items: [
      { text: "WRK TOP", value: "WRK_TOP" },
      { text: "Customer", value: "CUSTOMER" },
    ],
    Bulk_Upload_Items: [
      { text: "Customers", value: "CUSTOMER" },
      { text: "Users", value: "USER_BULK_UPLOAD" },
      { text: "Product Fleet", value: "PRODUCT_FLEET" },
      { text: "Dealers", value: "DEALERS_BULK_UPLOAD" },
      { text: "Territories", value: "TERRITORY_BULK_UPLOAD" },
      { text: "Components", value: "COMPONENTS_BULK_UPLOAD" },
      { text: "Service Partners", value: "SERVICE_PARTNERS_BULK_UPLOAD" },
      { text: "Product Tagging", value: "PRODUCT_TAGGING_TO_COMPONENTS" },
      { text: "Customer Territories", value: "CUSTOMER_TERRITORY_TAGGING" },
      {
        text: "Components Inventory",
        value: "COMPONENTS_INVENTORY_BULK_UPLOAD",
      },
      {
        text: "Enterprise Representatives",
        value: "ENTERPRISE_REPRESENTATIVE_BULK_UPLOAD",
      },
    ],
    orgtypes: ["ENTERPRISE", "PARTNER"],
    pricing_model_List: [
      { text: "SERVICE CREDITS", value: "SERVICE_CREDITS" },
      { text: "USERS", value: "USERS" },
      { text: "FLAT FEE", value: "FLAT_FEE" },
    ],
    Creditpermodule: [
      {
        credits_charge: "10",
        is_enabled: true,
        module_name: "TICKET",
      },
      {
        credits_charge: "2",
        is_enabled: true,
        module_name: "QUOTATION",
      },
      {
        credits_charge: "1",
        is_enabled: true,
        module_name: "STORE_MANAGEMENT",
      },
    ],
    SnackBarComponent: {},
    enableQuotGen: undefined
  }),
  watch: {
    AddOrgdialog(val) {
      if (val == true) {
        this.organization_type = "ENTERPRISE";
        for (let i = 0; i < CountryList.length; i++) {
          this.CountryList.push(CountryList[i]);
        }
        if (this.StoreObj.action == "EDIT") {
          this.organization_type = this.StoreObj.organization_type;
          this.organization_name = this.StoreObj.organization_name;
          this.organization_cost = this.StoreObj.organization_cost;
          this.country_name = this.StoreObj.country_name;
          this.organization_person_email_id =
            this.StoreObj.organization_person_email_id;
          this.ticket_credits = this.StoreObj.ticket_credits;
          this.monthly_cost = this.StoreObj.monthly_cost;
          this.organization_ticket_prefix =
            this.StoreObj.organization_ticket_prefix;
          this.enablecoordinator = this.StoreObj.enable_co_ordinator_app;
          this.TicketsType = this.StoreObj.ticket_type_availability;
          this.enablecreditRecharge = this.StoreObj.enable_credits_recharge;
          this.enableswitchOrganization =
            this.StoreObj.enable_switch_between_organization;
          this.whatsapp_notification = this.StoreObj.whatsapp_notification;
          this.whatsapp_notification_type =
            this.StoreObj.whatsapp_notification_type;
          this.sms_provider = this.StoreObj.sms_provider;
          this.is_actual_client = this.StoreObj.is_actual_client;
          this.pricing_model = this.StoreObj.pricing_model;
          this.is_enable_bulk_upload = this.StoreObj.is_enable_bulk_upload;
          this.approved_bulk_upload = this.StoreObj.approved_bulk_upload;
          this.enable_raydeo_dashboard_notification =
            this.StoreObj.enable_raydeo_dashboard_notification;
          this.dashboard_channel_api_key =
            this.StoreObj.dashboard_channel_api_key;
          this.dashboard_x_api_key = this.StoreObj.dashboard_x_api_key;
          this.enable_raydeo_ticket_trail_notification =
            this.StoreObj.enable_raydeo_ticket_trail_notification;
          this.ticket_trail_channel_api_key =
            this.StoreObj.ticket_trail_channel_api_key;
          this.ticket_trail_x_api_key = this.StoreObj.ticket_trail_x_api_key;
          this.enableQuotGen = this.StoreObj.enable_quotation_generation
          if (this.StoreObj.module_settings != undefined) {
            this.Creditpermodule = this.StoreObj.module_settings;
          } else {
            this.Creditpermodule = [
              {
                credits_charge: "0",
                is_enabled: false,
                module_name: "TICKET",
              },
              {
                credits_charge: "0",
                is_enabled: false,
                module_name: "QUOTATION",
              },
              {
                credits_charge: "0",
                is_enabled: false,
                module_name: "STORE_MANAGEMENT",
              },
            ];
          }
        }
      }
    },
  },
  methods: {
    async editOrganization() {
      this.isLoading = true;
      try {
        var inputParams = {
          organization_id: this.StoreObj.organization_id,
          organization_name: this.organization_name,
          organization_cost: this.organization_cost,
          organization_ticket_prefix: this.organization_ticket_prefix,
          updater_user_id: this.$store.getters.get_current_user_details.user_id,
          enable_co_ordinator_app: this.enablecoordinator,
          ticket_type_availability: this.TicketsType,
          enable_credits_recharge: this.enablecreditRecharge,
          enable_switch_between_organization: this.enableswitchOrganization,
          whatsapp_notification: this.whatsapp_notification,
          whatsapp_notification_type:
            this.whatsapp_notification == true
              ? this.whatsapp_notification_type
              : undefined,
          is_actual_client: this.is_actual_client,
          pricing_model: this.pricing_model,
          module_settings: this.Creditpermodule,
          is_enable_bulk_upload: this.is_enable_bulk_upload,
          approved_bulk_upload: this.approved_bulk_upload,
          enable_raydeo_dashboard_notification:
            this.enable_raydeo_dashboard_notification,
          dashboard_channel_api_key:
            this.enable_raydeo_dashboard_notification == true
              ? this.dashboard_channel_api_key
              : undefined,
          dashboard_x_api_key:
            this.enable_raydeo_dashboard_notification == true
              ? this.dashboard_x_api_key
              : undefined,
          enable_raydeo_ticket_trail_notification:
            this.enable_raydeo_ticket_trail_notification,
          ticket_trail_channel_api_key:
            this.enable_raydeo_ticket_trail_notification == true
              ? this.ticket_trail_channel_api_key
              : undefined,
          ticket_trail_x_api_key:
            this.enable_raydeo_ticket_trail_notification == true
              ? this.ticket_trail_x_api_key
              : undefined,
          enable_quotation_generation: this.enableQuotGen
        };
        if (
          this.monthly_cost != null &&
          this.monthly_cost != "" &&
          this.monthly_cost != undefined
        ) {
          inputParams.monthly_cost = this.monthly_cost;
        }
        if (
          this.sms_provider != undefined &&
          this.sms_provider != "" &&
          this.sms_provider != null
        ) {
          inputParams.sms_provider = this.sms_provider;
        }
        if (
          this.monthly_cost1 != null &&
          this.monthly_cost1 != "" &&
          this.monthly_cost1 != undefined
        ) {
          inputParams.monthly_cost1 = this.monthly_cost1;
        }
        if (
          this.ticket_credits != null &&
          this.ticket_credits != "" &&
          this.ticket_credits != undefined
        ) {
          inputParams.ticket_credits = this.ticket_credits;
        }
        let result = await API.graphql(
          graphqlOperation(Update_Organization, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.Update_Organization);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.AddOrgdialogEmit((this.Toggle = 2));
        }
        this.isLoading = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.isLoading = false;
      }
    },
    callmethodUppercase(organization_ticket_prefix) {
      this.organization_ticket_prefix =
        organization_ticket_prefix.toUpperCase();
    },
    validateaddOrgs() {
      if (this.$refs.form.validate()) {
        if (this.StoreObj.action == "CREATE") {
          this.addOrganization();
        } else if (this.StoreObj.action == "EDIT") {
          this.editOrganization();
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fill all required fields",
        };
      }
    },
    async addOrganization() {
      this.isLoading = true;
      try {
        var inputParams = {
          organization_name: this.organization_name,
          organization_cost: this.organization_cost,
          country_name: this.country_name,
          organization_ticket_prefix: this.organization_ticket_prefix,
          organization_person_email_id:
            this.organization_person_email_id.toLowerCase(),
          creator_user_id: this.$store.getters.get_current_user_details.user_id,
          organization_type: this.organization_type,
          enable_co_ordinator_app: this.enablecoordinator,
          ticket_type_availability: this.TicketsType,
          enable_credits_recharge: this.enablecreditRecharge,
          enable_switch_between_organization: this.enableswitchOrganization,
          whatsapp_notification: this.whatsapp_notification,
          whatsapp_notification_type:
            this.whatsapp_notification == true
              ? this.whatsapp_notification_type
              : undefined,
          is_actual_client: this.is_actual_client,
          is_enable_bulk_upload: this.is_enable_bulk_upload,
          approved_bulk_upload: this.approved_bulk_upload,
          pricing_model: this.pricing_model,
          enable_quotation_generation: this.enableQuotGen,
          module_settings: this.Creditpermodule.filter(
            (item) => item.module_name != "" && item.module_name != undefined
          ),
        };
        if (
          this.ticket_credits != null &&
          this.ticket_credits != "" &&
          this.ticket_credits != undefined
        ) {
          inputParams.ticket_credits = this.ticket_credits;
        }
        if (
          this.monthly_cost != null &&
          this.monthly_cost != "" &&
          this.monthly_cost != undefined
        ) {
          inputParams.monthly_cost = this.monthly_cost;
        }
        if (
          this.monthly_cost1 != null &&
          this.monthly_cost1 != "" &&
          this.monthly_cost1 != undefined
        ) {
          inputParams.monthly_cost1 = this.monthly_cost1;
        }
        let result = await API.graphql(
          graphqlOperation(Create_Organization, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.Create_Organization);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.AddOrgdialogEmit((this.Toggle = 2));
        }
        this.isLoading = false;
      } catch (error) {
        console.warn("Check The Error", error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.isLoading = false;
      }
    },
    AddOrgdialogEmit(Toggle) {
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>

<style scoped>
.fixed-bar {
  position: sticky;
  top: 0px;
  z-index: 2;
}
</style>
