import { listAttendanceHistory } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetOrgAttandance = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      GetOrgAttandanceList: [],
    };
  },
  methods: {
    async GetOrgAttandanceMethod(
      organization_id,
      from_date,
      to_date,
      attendance_status
    ) {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(listAttendanceHistory, {
            input: {
              organization_id: organization_id,
              from_date: from_date,
              to_date: to_date,
              attendance_status: attendance_status,
            },
          })
        );
        this.GetOrgAttandanceList = JSON.parse(
          result.data.listAttendanceHistory
        ).data.items;
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.GetOrgAttandanceList = [];
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
