<template>
  <div>
    <Overlay :overlay="overlay" />
    <v-toolbar dense class="elevation-0 mx-3">
      <h3>{{ StoreObj.organization_name }}'s Organization Settings</h3>
    </v-toolbar>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import { GetAllMasterCategories } from "@/mixins/GetAllMasterCategories.js";
import { GetAllOrganizationSettingsTypesInMS } from "@/mixins/GetAllEnterpriseDetails.js";
export default {
  props: {
    StoreObj: Object,
  },
  components: {
    Overlay,
  },
  data() {
    return {
      overlay: false,
      GetAllMasterCategoriesList: [],
      GetAllMasterCategoriesListFilter: [],
    };
  },
  mixins: [GetAllMasterCategories, GetAllOrganizationSettingsTypesInMS],
  mounted() {
    this.GetAllOrganizationSettingsTypesMethod(this.StoreObj.organization_id);
    this.GetAllMasterCategoriesMethod();
  },
};
</script>

<style></style>
