<template>
  <div>
    <v-toolbar dense class="elevation-0">
      <h3>
        <span v-if="StoreObj.organization_name"
          >{{ StoreObj.organization_name }}'s</span
        >
        Organization Details
      </h3>
    </v-toolbar>
    <v-card class="elevation-0">
      <v-card-text>
        <v-row no-gutters class="black--text">
          <v-col cols="12" md="4" sm="12" xs="12">
            <p>
              <b>Organization Type : </b>
              {{ StoreObj.organization_type }}
            </p>
          </v-col>
          <v-col cols="12" md="4" sm="12" xs="12">
            <p>
              <b>Organization Person Mail : </b>
              {{ StoreObj.organization_person_email_id }}
            </p>
          </v-col>
          <v-col cols="12" md="4" sm="12" xs="12">
            <p>
              <b>Ticket Prefix : </b>
              {{ StoreObj.organization_ticket_prefix }}
            </p>
          </v-col>
          <v-col cols="12" md="4" sm="12" xs="12">
            <p>
              <b>Ticket Type : </b>
              {{ StoreObj.ticket_type_availability }}
            </p>
          </v-col>
          <v-col cols="12" md="4" sm="12" xs="12">
            <p>
              <b>Onboarding Price : </b>
              {{ StoreObj.organization_cost }}
            </p>
          </v-col>
          <v-col cols="12" md="4" sm="12" xs="12">
            <p>
              <b>Pricing Modal : </b>
              {{ StoreObj.pricing_model }}
            </p>
          </v-col>
          <v-col cols="12" md="4" sm="12" xs="12">
            <p>
              <b>Available Ticket Credits : </b>
              {{ StoreObj.ticket_credits }}
            </p>
          </v-col>
          <v-col cols="12" md="4" sm="12" xs="12">
            <p>
              <b>Organization Address : </b>
              {{ StoreObj.organization_address }}
            </p>
          </v-col>
          <v-col cols="12" md="4" sm="12" xs="12"> </v-col>
          <v-col cols="12" md="4" sm="12" xs="12">
            <p>
              <b>Individual Customers : </b>
              {{ StoreObj.individual_customers }}
            </p>
          </v-col>
          <v-col cols="12" md="4" sm="12" xs="12">
            <p>
              <b>Business Customers : </b>
              {{ StoreObj.business_customers }}
            </p>
          </v-col>
          <v-col cols="12" md="4" sm="12" xs="12">
            <p>
              <b>Business Customers : </b>
              {{ StoreObj.business_customers }}
            </p>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-4">
          <v-col cols="12" md="3" sm="12" xs="12">
            <v-card height="140">
              <v-card-text class="largeFontSizeNew--text" align="left">
                <div>
                  <h3 class="mb-2 black--text">Attendance</h3>
                  <h5 class="black--text darken-3">Today :</h5>
                  <h5 class="black--text darken-3">This Month :</h5>
                  <div
                    @mouseover="upHeree = true"
                    @mouseleave="upHeree = false"
                  >
                    <h5 class="mb-3 black--text">Overall Tickets :</h5>
                    <v-row>
                      <v-col cols="12" md="6">
                        <h5 v-show="upHeree" class="black--text darken-3">
                          Active :
                        </h5>
                      </v-col>
                      <v-col cols="12" md="6">
                        <h5 v-show="upHeree" class="black--text darken-3">
                          Closed :
                        </h5>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" sm="12" xs="12">
            <v-card class="mx-4" height="140">
              <v-card-text class="largeFontSizeNew--text" align="left">
                <div>
                  <h3 class="mb-2 black--text">Tickets</h3>
                  <h5 class="black--text darken-3">Today :</h5>
                  <h5 class="black--text darken-3">This Month :</h5>
                  <div
                    @mouseover="upHeree = true"
                    @mouseleave="upHeree = false"
                  >
                    <h5 class="mb-3 black--text">Overall Tickets :</h5>
                    <v-row>
                      <v-col cols="12" md="6">
                        <h5 v-show="upHeree" class="black--text darken-3">
                          Active :
                        </h5>
                      </v-col>
                      <v-col cols="12" md="6">
                        <h5 v-show="upHeree" class="black--text darken-3">
                          Closed :
                        </h5>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" sm="12" xs="12">
            <v-card class="mr-4" height="140">
              <v-card-text class="largeFontSizeNew--text" align="left">
                <div>
                  <h3 class="mb-2 black--text">Users</h3>
                  <h5 class="black--text darken-3">Today :</h5>
                  <h5 class="black--text darken-3">This Month :</h5>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" sm="12" xs="12">
            <v-card class="mr-4" height="140">
              <v-card-text class="largeFontSizeNew--text" align="left">
                <div>
                  <h3 class="mb-2 black--text">Credits</h3>
                  <h5 class="black--text darken-3">
                    Consumed :
                    <span>{{ StoreObj.consumed_ticket_credits }}</span>
                  </h5>
                  <h5 class="black--text darken-3">
                    Balance :
                    <span>
                      {{ StoreObj.ticket_credits }}
                    </span>
                  </h5>
                  <h5 class="black--text darken-3">
                    Credits Per Charge :<span
                      v-if="StoreObj.module_settings != undefined"
                    >
                      {{ StoreObj.module_settings[0].credits_charge }}
                    </span>
                  </h5>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    tab: Number,
    StoreObj: Object,
  },
  data() {
    return {
      upHere: false,
      upHeree: false,
    };
  },
};
</script>

<style></style>
