<template>
  <div>
    <TicketTabCard
      v-if="current_view == 'TABVIEW'"
      :StoreObj="StoreObj"
      @clicked="EmitTab"
    />
    <Overlay :overlay="overlay" />
    <v-card class="elevation-0" v-if="current_view == 'LIST'">
      <v-row no-gutters>
        <v-toolbar dense class="elevation-0 mx-3">
          <h3>{{ StoreObj.organization_name }}'s Active Tickets</h3>
          <v-spacer></v-spacer>
          <v-menu right bottom :close-on-content-click="CloseValue" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-on="on"
                v-bind="attrs"
                class="primary white--text ml-2"
              >
                <v-icon v-if="applied_filters == false" small
                  >mdi-filter</v-icon
                >
                <v-icon v-else-if="applied_filters == true" small
                  >mdi-filter-off</v-icon
                >
              </v-btn>
            </template>
            <v-card width="400px">
              <v-row>
                <v-col cols="12" md="6" sm="12" xs="12">
                  <v-menu ref="menuStart" :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startdate"
                        label="Starting date"
                        outlined
                        dense
                        class="FontSize pl-2 mt-5 ml-4"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="startdate" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menuStart = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menuStart.save(startdate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" sm="12" xs="12">
                  <v-menu
                    ref="menuEnd"
                    v-model="menuEnd"
                    :close-on-content-click="false"
                    :return-value.sync="enddate"
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="enddate"
                        label="Ending date"
                        outlined
                        dense
                        width="290px"
                        class="FontSize mr-3 mt-5"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="enddate"
                      no-title
                      scrollable
                      :min="startdate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menuEnd = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menuEnd.save(enddate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="mt-n5">
                <v-col cols="12" md="6" sm="12" xs="12">
                  <v-select
                    dense
                    outlined
                    class="FontSize mr-4"
                    label="Created By"
                    :items="created_by_items"
                    v-model="createdBy"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="mt-n6">
                <v-col cols="12" md="6" xs="12" sm="12">
                  <v-select
                    dense
                    outlined
                    class="FontSize ml-6"
                    label="User Type"
                    item-text="text"
                    item-value="value"
                    v-model="userType"
                    :items="
                      this.$store.getters.get_user_type == 'DEALER_OWNER'
                        ? user_type_items_dealer
                        : this.$store.getters.get_user_type == 'SERVICE_PARTNER'
                        ? user_type_items_service_partner
                        : user_type_items_owner
                    "
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row
                class="mt-n5"
                v-if="userType != 'ENTERPRISE_REPRESENTATIVE'"
              >
                <v-col cols="12" md="6" sm="12" xs="12">
                  <v-autocomplete
                    outlined
                    dense
                    class="FontSize mr-4"
                    :label="
                      userType == 'SERVICE_PARTNER'
                        ? 'Partner Users'
                        : 'Dealer Users'
                    "
                    :items="
                      userType == 'SERVICE_PARTNER'
                        ? GetAllPartnerAndPartnerUserListForTicketFilter
                        : userType == 'DEALER_OWNER'
                        ? GetDealersListForTicketFilter
                        : []
                    "
                    item-text="user_name"
                    item-value="user_id"
                    v-model="dealerOrPartnerUserid"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="mt-n5">
                <v-col cols="12" md="6" sm="12" xs="12">
                  <v-autocomplete
                    dense
                    outlined
                    class="FontSize mr-3"
                    label="Ticket Status"
                    v-model="ticket_status"
                    :items="ticketOpenstatusItems"
                    item-text="text"
                    item-value="value"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="mt-n5">
                <v-col cols="12" md="6" sm="12" xs="12">
                  <v-select
                    dense
                    outlined
                    label="Ticket Type"
                    class="FontSize ml-6"
                    v-model="ticket_creation_type"
                    :items="ticket_create_type_items"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" sm="12" xs="12">
                  <v-autocomplete
                    dense
                    outlined
                    label="Support"
                    v-model="support"
                    class="FontSize mr-3"
                    :items="support_items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <div class="text-center">
                <v-btn
                  small
                  class="mb-6"
                  color="primary"
                  @click="SortByDateMethod()"
                  ><v-icon class="white--text" small>mdi-filter</v-icon
                  >Filter</v-btn
                >
                <v-btn
                  small
                  color="primary"
                  class="mb-6 ml-2"
                  @click="ClearFilterMethod()"
                  ><v-icon class="white--text" small>mdi-close</v-icon
                  >Clear</v-btn
                >
              </div>
            </v-card>
          </v-menu>
        </v-toolbar>
      </v-row>
      <v-col cols="12" xs="12" sm="12" md="12">
        <v-card-text class="mt-n4">
          <v-data-table
            dense
            fixed-header
            :page.sync="page"
            class="elevation-0"
            :items-per-page="50"
            :height="TableHeight"
            style="cursor: pointer"
            :no-data-text="noDataText"
            @click:row="handleRowClick"
            :headers="openTicketHeaders"
            @page-count="pageCount = $event"
            :loading="loading_active_tickets"
            :items="GetAllOpenTicketList"
            :footer-props="{
              'disable-items-per-page': true,
            }"
          >
            <template v-slot:[`item.ticket_id`]="{ item }">
              <div class="ml-n10">
                <div
                  class="FontSize font-weight-bold"
                  v-if="item.custom_ticket_id == undefined"
                >
                  {{
                    `${item.ticket_id.split("-")[1]}-${
                      item.ticket_id.split("-")[2]
                    }-${item.ticket_id.split("-")[3]}-${
                      item.ticket_id.split("-")[4]
                    }`
                  }}
                </div>
                <div
                  class="FontSize font-weight-bold"
                  v-else-if="item.custom_ticket_id != undefined"
                >
                  {{ item.custom_ticket_id }}
                </div>
                <div
                  class="FontSize"
                  v-if="item.ticket_creation_type != undefined"
                >
                  <span class="FontSize">{{
                    item.ticket_creation_type.replaceAll("_", " ")
                  }}</span>
                </div>
                <div v-else>-</div>
                <div class="FontSize" v-if="item.representative_name">
                  <span class="FontSize">{{ item.representative_name }}</span>
                </div>
                <div v-else>-</div>
              </div>
              <v-btn
                v-if="item.requested_serial_number != undefined"
                x-small
                color="orange white--text"
                class="ml-n8"
                @click.stop="checkItem(item, 'PRODUCT_FLEET_REQUEST')"
                >Requested For Product Fleet</v-btn
              >
            </template>
            <template v-slot:[`item.customer_company_name`]="{ item }">
              <div
                class="FontSize"
                v-if="item.customer_company_name != undefined"
              >
                {{ item.customer_company_name }}
              </div>
              <div v-else>-</div>
              <div class="FontSize" v-if="item.customer_name != undefined">
                {{ item.customer_name }}
              </div>
              <div v-else>-</div>
              <div v-if="item.ticket_city != undefined" class="FontSize">
                {{ item.ticket_city }}
              </div>
            </template>
            <template v-slot:[`item.category_name`]="{ item }">
              <div class="FontSize">{{ item.category_name }}</div>
              <div class="FontSize">{{ item.service_name }}</div>
              <div
                class="FontSize primary--text"
                v-if="item.additional_ticket_status"
              >
                {{ item.additional_ticket_status.replaceAll("_", " ") }}
              </div>
              <div class="FontSize" v-else-if="item.ticket_status != undefined">
                <span class="primary--text"
                  >{{ item.ticket_status.replaceAll("_", " ") }}
                </span>
              </div>
              <div v-else>-</div>
            </template>
            <template v-slot:[`item.ticket_created_on`]="{ item }">
              <div class="FontSize">
                {{
                  new Date(item.ticket_created_on * 1000).toLocaleString(
                    "en-GB"
                  )
                }}
              </div>
              <div class="FontSize text-capitalize">
                <span>{{ item.service_type_name }}</span>
              </div>
            </template>
            <template v-slot:[`item.ticket_warranty_type`]="{ item }">
              <div class="FontSize text-capitalize font-weight-bold">
                {{
                  item.ticket_warranty_type != undefined
                    ? item.ticket_warranty_type
                    : "-"
                }}
              </div>
              <div
                class="FontSize text-capitalize"
                v-if="item.ticket_warranty_type != undefined"
              >
                <span class="text-capitalize">{{
                  item.support_type != undefined ? item.support_type : ""
                }}</span>
              </div>
              <div
                class="FontSize"
                v-if="item.additional_comments != undefined"
              >
                {{ item.additional_comments }}
              </div>
              <div v-else>-</div>
            </template>
          </v-data-table>
          <v-pagination
            v-model="page"
            :length="pageCount"
            total-visible="4"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          ></v-pagination>
        </v-card-text>
      </v-col>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import { GetAllOpenTicket } from "@/mixins/GetAllTickets.js";
export default {
  mixins: [GetAllOpenTicket],
  props: {
    StoreObj: Object,
  },
  components: { Overlay },
  data: () => ({
    page: 1,
    count: 0,
    length: 0,
    pageCount: 1,
    TableHeight: 0,
    menu: false,
    overlay: false,
    menuEnd: false,
    menuStart: false,
    CloseValue: false,
    closeDilaog: false,
    isSearching: false,
    dialogDiscard: false,
    showEditButton: false,
    applied_filters: false,
    showCreateButton: false,
    dialogAddRemarks: false,
    create_ticket_btn: true,
    dialogReassignTicket: false,
    loading_active_tickets: false,
    deactivateTicketDilaog: false,
    dialogStopOrResetTicket: false,
    dialogChangeTicketStatus: false,
    dialogChangeVisitDateTime: false,
    dialogProductFleetRequest: false,
    dialogTicketAcceptOrReject: false,
    dialogComponentsInformation: false,
    showActivateDeactivateButton: false,
    search: "",
    enddate: "",
    startdate: "",
    support: "ALL",
    noDataText: "",
    createdBy: "ALL",
    support_type: "ALL",
    current_view: "LIST",
    ticket_status: "ALL",
    selectProduct: "ALL",
    SearchBy: "ticket_id",
    dealerOrParterUsers: "",
    wip_sub_stage_id: "ALL",
    selectServiceType: "ALL",
    active_ticket_count: "",
    selectTechnicianType: "ALL",
    ticket_creation_type: "ALL",
    dealerOrPartnerUserid: "ALL",
    Current_Filter: "WITHOUT_SEARCH",
    userType: "",

    created_by_items: [
      { text: "ALL", value: "ALL" },
      { text: "OWNER", value: "OWNER" },
      { text: "ADMIN", value: "ADMIN" },
      { text: "MANAGER", value: "MANAGER" },
      { text: "SERVICE CO-ORDINATOR", value: "AGENT" },
      { text: "TECHNICIAN", value: "REPRESENTATIVE" },
      { text: "SERVICE PARTNER", value: "SERVICE_PARTNER" },
      { text: "PARTNER SERVICE CO-ORDINATOR", value: "PARTNER_AGENT" },
      { text: "PARTNER REPRESENTATIVE", value: "PARTNER_REPRESENTATIVE" },
      { text: "DEALER OWNER", value: "DEALER_OWNER" },
      { text: "DEALER TECHNICIAN", value: "DEALER_TECHNICIAN" },
      { text: "DEALER SERVICE CO-ORDINATOR", value: "DEALER_AGENT" },
    ],
    user_type_items_owner: [
      { text: "Enterprise Representative", value: "ENTERPRISE_REPRESENTATIVE" },
      { text: "Service Partner", value: "SERVICE_PARTNER" },
      { text: "Dealer", value: "DEALER_OWNER" },
    ],
    user_type_items_dealer: [{ text: "Dealer", value: "DEALER_OWNER" }],
    user_type_items_service_partner: [
      { text: "Service Partner", value: "SERVICE_PARTNER" },
    ],
    SearchByItems: [
      {
        text: "Ticket ID",
        value: "ticket_id",
      },
      {
        text: "Customer",
        value: "customer_company_name",
      },
      {
        text: "Contact Person",
        value: "customer_name",
      },
      {
        text: "Customer ID",
        value: "customer_unique_id",
      },
      {
        text: "Phone Number",
        value: "customer_full_number",
      },
      {
        text: "Serial Number",
        value: "service_serial_number",
      },
    ],
    ticket_create_type_items: [
      { text: "ALL", value: "ALL" },
      { text: "Breakdown", value: "BREAKDOWN" },
      { text: "Installation", value: "INSTALLATION" },
      { text: "Schedule Service", value: "SCHEDULE_SERVICE" },
      { text: "Core Service Job", value: "CORE_SERVICE_JOB" },
      { text: "Non-Core Service Job", value: "NON_CORE_SERVICE_JOB" },
      { text: "Sundary Job", value: "SUNDARY_JOB" },
      { text: "Other", value: "OTHER" },
    ],
    support_items: [
      { text: "ALL", value: "ALL" },
      { text: "GUARANTEE", value: "GUARANTEE" },
      { text: "WARRANTY", value: "WARRANTY" },
      { text: "CONTRACT", value: "CONTRACT" },
      { text: "EXTENDED WARRANTY", value: "EXTENDED_WARRANTY" },
      { text: "NO WARRANTY", value: "NO_WARRANTY" },
    ],
    openTicketHeaders: [
      {
        text: "#",
        value: "work_status",
        tooltip_text: "Serial Number",
        width: "8%",
      },
      {
        text: "Ticket",
        value: "ticket_id",
        tooltip_text: "Ticket Details",
        width: "20%",
      },
      {
        text: "Customer",
        value: "customer_company_name",
        tooltip_text: "Customer Details",
        width: "20%",
      },
      {
        text: "Product",
        value: "category_name",
        tooltip_text: "Product Details",
        width: "14%",
      },
      {
        text: "Created",
        value: "ticket_created_on",
        tooltip_text: "Ticket Created On",
        width: "10%",
      },
      {
        text: "Support",
        value: "ticket_warranty_type",
        tooltip_text: "Support",
      },
      {
        text: "Comp.",
        value: "requested_components_details",
        tooltip_text: "Components Required",
        width: "4%",
      },
    ],
    ticketOpenstatusItems: [
      { text: "ALL", value: "ALL" },
      { text: "OPEN", value: "OPEN" },
      { text: "CLAIMED", value: "CLAIMED" },
      { text: "ASSIGNED", value: "ASSIGNED" },
      { text: "WIP", value: "WORK_IN_PROGRESS" },
      { text: "SELF ASSIGNED", value: "SELF_ASSIGNED" },
    ],
    SnackBarComponent: {},
  }),
  watch: {
    support(val) {
      this.GetAllAMCTypeMethod("ACTIVE", val);
    },
    dealerOrParterUsers(val) {
      if (this.userType == "SERVICE_PARTNER") {
        this.GetAllPartnerAndPartnerUserListForTicketFilter = [];
        this.GetAllPartnerUserForTicketFilterMethod(
          val,
          "ACTIVE",
          this.selectTechnicianType
        );
      } else if (this.userType == "DEALER_OWNER") {
        this.GetDealersListForTicketFilter = [];
        this.GetDealersMethodForTicketFilter(
          val,
          "ACTIVE",
          this.selectTechnicianType,
          undefined
        );
      }
    },
    page() {
      if (
        this.next_token != undefined &&
        this.next_token != null &&
        this.next_token != ""
      ) {
        this.GetAllOpenTicketMethod(
          this.StoreObj.organization_id,
          undefined,
          this.ticket_status == "ALL" ? undefined : this.ticket_status,
          this.selectServiceType == "ALL" ? undefined : this.selectServiceType,
          this.selectTechnicianType == "ALL"
            ? undefined
            : this.dealerOrPartnerUserid != "ALL" &&
              this.dealerOrPartnerUserid != "" &&
              this.dealerOrPartnerUserid != undefined
            ? this.dealerOrPartnerUserid
            : this.selectTechnicianType,
          this.startdate,
          this.enddate,
          this.selectProduct == "ALL" ? undefined : this.selectProduct,
          this.ticket_creation_type == "ALL"
            ? undefined
            : this.ticket_creation_type,
          this.support == "ALL" ? undefined : this.support,
          this.support_type == "ALL" ? undefined : this.support_type,
          this.next_token,
          this.createdBy == "ALL" ? undefined : this.createdBy,
          this.wip_sub_stage_id == "ALL" ? undefined : this.wip_sub_stage_id,
          this.dealerOrPartnerUserid == "ALL" &&
            (this.userType == "SERVICE_PARTNER" ||
              this.userType == "DEALER_OWNER") &&
            this.selectTechnicianType != "ALL"
            ? this.userType
            : undefined
        );
      }
    },
  },
  mounted() {
    this.TableHeight = window.innerHeight - 220;
    this.startdate = new Date(new Date().getTime() - 365 * 86400000)
      .toISOString()
      .substr(0, 10);
    this.enddate = new Date(new Date().getTime() + 30 * 86400000)
      .toISOString()
      .substr(0, 10);
    this.GetAllOpenTicketMethod(
      this.StoreObj.organization_id,
      undefined,
      "OPEN",
      this.selectServiceType == "ALL" ? undefined : this.selectServiceType,
      this.selectTechnicianType == "ALL"
        ? undefined
        : this.dealerOrPartnerUserid != "ALL" &&
          this.dealerOrPartnerUserid != "" &&
          this.dealerOrPartnerUserid != undefined
        ? this.dealerOrPartnerUserid
        : this.selectTechnicianType,
      this.startdate,
      this.enddate,
      this.selectProduct == "ALL" ? undefined : this.selectProduct,
      this.ticket_creation_type == "ALL"
        ? undefined
        : this.ticket_creation_type,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
    this.userType =
      this.$store.getters.get_user_type == "DEALER_OWNER"
        ? "DEALER_OWNER"
        : this.$store.getters.get_user_type == "SERVICE_PARTNER"
        ? "SERVICE_PARTNER"
        : "ENTERPRISE_REPRESENTATIVE";
  },
  methods: {
    GetTicketsByFilterMethod(isSearching = true) {
      if (this.search != "") {
        this.Current_Filter = "WITH_SEARCH";
        this.GetAllTicketsBySearchMethod(
          this.search,
          this.SearchBy,
          undefined,
          undefined
        );
      } else {
        this.renderComp = false;
        this.isSearching = false;
        this.$nextTick(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Type Something...",
          };
          this.renderComp = true;
        });
      }
    },
    ClearMethod() {
      this.Current_Filter = "WITHOUT_SEARCH";
      this.GetAllOpenTicketList = [];
      setTimeout(() => {
        this.GetAllOpenTicketMethod(
          this.StoreObj.organization_id,
          undefined,
          this.ticket_status == "ALL" ? undefined : this.ticket_status,
          this.selectServiceType == "ALL" ? undefined : this.selectServiceType,
          this.selectTechnicianType == "ALL"
            ? undefined
            : this.dealerOrPartnerUserid != "ALL" &&
              this.dealerOrPartnerUserid != "" &&
              this.dealerOrPartnerUserid != undefined
            ? this.dealerOrPartnerUserid
            : this.selectTechnicianType,
          this.startdate,
          this.enddate,
          this.selectProduct == "ALL" ? undefined : this.selectProduct,
          this.ticket_creation_type == "ALL"
            ? undefined
            : this.ticket_creation_type,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined
        );
      }, 300);
      setTimeout(() => {
        this.GetAllDashboardDataMethod();
      }, 500);
      this.search = "";
    },

    EmitTab() {
      this.current_view = "LIST";
    },
    handleRowClick(item) {
      this.StoreObj = item;
      this.current_view = "TABVIEW";
      this.StoreObj.action = "MOREACTION";
      this.count = 1;
    },
    SortByDateMethod() {
      this.CloseValue = true;
      this.applied_filters = true;
      this.GetAllOpenTicketList = [];
      this.GetAllOpenTicketMethod(
        this.StoreObj.organization_id,
        undefined,
        this.ticket_status == "ALL" ? undefined : this.ticket_status,
        this.selectServiceType == "ALL" ? undefined : this.selectServiceType,
        this.selectTechnicianType == "ALL"
          ? undefined
          : this.dealerOrPartnerUserid != "ALL" &&
            this.dealerOrPartnerUserid != "" &&
            this.dealerOrPartnerUserid != undefined
          ? this.dealerOrPartnerUserid
          : this.selectTechnicianType,
        this.startdate,
        this.enddate,
        this.selectProduct == "ALL" ? undefined : this.selectProduct,
        this.ticket_creation_type == "ALL"
          ? undefined
          : this.ticket_creation_type,
        this.support == "ALL" ? undefined : this.support,
        this.support_type == "ALL" ? undefined : this.support_type,
        undefined,
        this.createdBy == "ALL" ? undefined : this.createdBy,
        this.wip_sub_stage_id == "ALL" ? undefined : this.wip_sub_stage_id,
        this.dealerOrPartnerUserid == "ALL" &&
          (this.userType == "SERVICE_PARTNER" ||
            this.userType == "DEALER_OWNER") &&
          this.selectTechnicianType != "ALL"
          ? this.userType
          : undefined
      );
      setTimeout(() => {
        this.CloseValue = false;
      }, 1000);
      setTimeout(() => {
        this.GetAllDashboardDataMethod();
      }, 500);
    },
  },
};
</script>
