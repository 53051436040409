import { ListBulkUploadAuditLogs } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetBulkUploadLogs = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      BulkUploadLogItems: [],
    };
  },
  methods: {
    async BulkUploadLogItemsMethod(organization_id, log_type) {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(ListBulkUploadAuditLogs, {
            input: {
              organization_id: organization_id,
              log_type: log_type
            },
          })
        );
        this.BulkUploadLogItems = JSON.parse(
          result.data.ListBulkUploadAuditLogs
        ).data.items;
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.BulkUploadLogItems = [];
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
