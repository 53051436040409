import { List_Organizations } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllOrganizations = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      RouterList: [],
      GetAllOrganizationsList: [],
    };
  },
  methods: {
    async GetAllOrganizationMethod() {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(List_Organizations, {
            input: {
              organization_status: this.organization_status,
            },
          })
        );
        this.GetAllOrganizationsList = JSON.parse(
          result.data.List_Organizations
        ).data.items;
        this.GetAllOrganizationsListFilter = JSON.parse(
          result.data.List_Organizations
        ).data.items;
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.GetAllOrganizationsList = [];
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
